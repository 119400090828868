<template>
  <v-container fluid>
    <div class="d-flex">
      <h2 class="mr-auto">帳號管理</h2>
    </div>

    <div class="d-flex mt-10 justify-space-between">
      <div class="d-flex">
        <v-text-field
          class="mr-2 inputWhiteBg searchField"
          v-model="keyword"
          outlined
          dense
          placeholder="搜尋帳號、名稱、角色"
        ></v-text-field>
        <v-btn color="primary" @click="getUsers">搜尋</v-btn>
      </div>
      <div>
        <!-- <v-btn class="mr-6" color="primary" @click="addAccount">新增帳號</v-btn> -->
        <v-btn
          v-if="rolesCheck('usersExport')"
          color="primary"
          @click="exportList"
          >匯出名單</v-btn
        >
      </div>
    </div>

    <material-table
      class="evelatioin-1"
      :headers="header"
      :items="items"
      :options.sync="options"
      :items-per-page="-1"
      hide-default-footer
    >
      <template #roles="{ item }">
        <!-- {{ item }} -->
        {{ transRoles(item.roles) }}
      </template>
      <template #updatedAt="{ item }">
        {{ dateFormate(item.updatedAt) }}
      </template>
      <template #actions="{ item }">
        <v-icon v-if="rolesCheck('usersEdit')" @click="editAccount(item)"
          >mdi-pencil</v-icon
        >
      </template>
    </material-table>

    <material-pagination
      :totalPages="totalPage"
      @pageFilter="pageFilter"
    ></material-pagination>

    <material-dialog v-model="editDialog" title="個別帳號設定" max-width="500">
      <material-Input
        class="align-center"
        noBorder
        outlined
        prependTitle="帳號"
        v-model="accountSetData.name"
        dense
      ></material-Input>
      <material-Input
        class="align-center"
        noBorder
        outlined
        prependTitle="名稱"
        v-model="accountSetData.name"
        dense
      ></material-Input>
      <material-select
        v-model="accountSetData.roles"
        prependTitle="角色"
        :items="roles"
        item-value="name"
        item-text="description"
        multiple
      ></material-select>
      <material-select
        v-model="accountSetData.agent"
        prependTitle="代理人"
        :items="agentList"
        :disabled="agentList.length == 0"
        item-text="name"
        item-value="id"
      ></material-select>
      <div class="d-flex align-center mb-5">
        <span class="mr-4" style="width: 75px">代理時間</span>
        <date-picker
          v-model="accountSetData.agentStart"
          type="datetime"
          placeholder="起始時間"
          format="YYYY-MM-DD HH:mm:ss"
          value-type="YYYY-MM-DD HH:mm:ss"
          :disabled="agentList.length == 0"
        ></date-picker>
        -
        <date-picker
          v-model="accountSetData.agentEnd"
          type="datetime"
          placeholder="結束時間"
          format="YYYY-MM-DD HH:mm:ss"
          value-type="YYYY-MM-DD HH:mm:ss"
          :disabled="agentList.length == 0"
        ></date-picker>
      </div>
      <div class="mb-5">維護單位</div>
      <div class="d-flex">
        <span v-for="(item, idx) in accountSetData.group" :key="idx">
          {{ item }}
          <span v-if="idx != 0 && idx != accountSetData.group.length - 1"
            >,</span
          >
        </span>
      </div>
      <template #actions>
        <v-spacer />
        <btn-cancel @click="cancel()">取消</btn-cancel>
        <v-btn color="error" @click="reject()">駁回</v-btn>
        <v-btn color="primary" @click="submit()">送簽</v-btn>
        <v-btn color="primary" @click="approve()">核可</v-btn>
        <v-btn color="success" @click="save()">儲存</v-btn>
      </template>
    </material-dialog>
  </v-container>
</template>
<script>
import Datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { dateFormateMixin, checkRolesMixin } from "../../helper";
import {
  apiGetUsers,
  apiGetExport,
  apiGetUsersById,
  apiGetRoles,
  apiSaveUsersById,
} from "../../api/settings";

export default {
  mixins: [dateFormateMixin, checkRolesMixin],
  components: {
    Datepicker,
  },
  data() {
    return {
      keyword: null,
      header: [
        { text: "帳號", value: "username", sortable: false },
        { text: "名稱", value: "name", sortable: false },
        { text: "部門", value: "department", sortable: false },
        { text: "角色", value: "roles", sortable: false },
        { text: "更新時間", value: "updatedAt" },
        { text: "操作", value: "actions", sortable: false },
      ],
      options: {
        sortBy: ["updatedAt"],
        sortDesc: [false],
      },
      users_total: 0,
      items: [],
      editDialog: false,
      roles: [],
      agentList: [],
      startDate: null,
      endDate: null,
      // 分頁參數
      page: 1,
      itemsPerPage: 10,
      totalPage: 1,

      accountSetData: {},
      // 翻譯表
      transRoleEnToTw: {
        ROLE_ADMINISTRATIVE: "行政人員",
        ROLE_AUDITOR: "稽核人員",
        ROLE_LEADER: "領隊",
        ROLE_DIRECTOR: "科主管",
        ROLE_DEPUTY_CHIEF_AUDITOR: "副總稽核",
        ROLE_CHIEF_AUDITOR: "總稽核",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getUsers();
      },
    },
  },
  mounted() {
    this.getUsers();
    this.getRoles();
  },
  methods: {
    // 分頁搜尋
    pageFilter() {
      let pageParams = arguments[0];
      this.page = pageParams.page;
      this.itemsPerPage = pageParams.itemsPerPage;
      this.getUsers();
    },
    async editAccount(item) {
      try {
        let res = await apiGetUsersById(item.id);
        this.accountSetData = { ...item, ...res.data };
      } catch (error) {}
      this.getAgents(item.department);
      this.editDialog = true;
    },
    // 取得使用者列表
    async getUsers() {
      try {
        let payload = this.makeUrlData();
        let res = await apiGetUsers(payload);
        this.items = res.data.content;
        console.log(res.data);
        this.totalPage = res.data.totalPages;
      } catch (error) {
        console.log(error);
      }
    },
    // 取得代理人列表
    async getAgents(department) {
      try {
        let searchData = {
          page: 1,
          size: 999,
          sortBy: "updatedAt",
          order: "asc",
        };
        let res = await apiGetUsers(searchData);
        let userList = res.data.content;
        this.agentList = userList.filter((el) => {
          return el.department == department;
        });
      } catch (error) {}
    },
    // 取得角色列表
    async getRoles() {
      try {
        let searchData = {
          page: 1,
          size: 999,
          sortBy: "id",
          order: "asc",
        };
        let res = await apiGetRoles(searchData);
        this.roles = res.data.content;
      } catch (error) {}
    },
    // 建立過濾網址參數
    makeUrlData() {
      let searchData = {};
      searchData["page"] = this.page;
      searchData["size"] = this.itemsPerPage;
      searchData["sortBy"] = [null, undefined, "", "updatedAt"].includes(
        this.options.sortBy[0]
      )
        ? "updatedAt"
        : this.options.sortBy[0];
      searchData["order"] = this.options.sortDesc[0] == true ? "asc" : "desc";
      searchData["keyword"] = this.keyword;
      return searchData;
    },
    async exportList() {
      try {
        let res = await apiGetExport();
        var data = res.data;
        var universalBOM = "\uFEFF";
        var blob = new Blob([universalBOM + data], {
          type: "application/octet-stream",
        });
        var href = URL.createObjectURL(blob);
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = href;
        link.download = "record.csv";
        link.click();
      } catch (error) {
        console.log("err exportList: ", error);
      }
    },
    cancel() {
      this.editDialog = false;
      // TODO
    },
    submit() {
      // TODO
    },
    reject() {
      // TODO
    },
    async save() {
      // TODO
      try {
        console.log(this.accountSetData);
        let updateData = {
          roles: this.accountSetData.roles,
          agent: this.accountSetData.agent,
          agentStart: this.accountSetData.agentStart,
          agentEnd: this.accountSetData.agentEnd,
          status: this.accountSetData.status,
        };
        let res = await apiSaveUsersById(this.accountSetData.id, updateData);
        this.getUsers();
        this.editDialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    approve() {
      // TODO
    },
    transRoles(roles) {
      console.log(roles);
      let showList = "";
      for (let [index, item] of roles.entries()) {
        if (index == roles.length - 1) {
          showList += this.transRoleEnToTw[item];
        } else {
          showList += this.transRoleEnToTw[item] + ", ";
        }
      }
      console.log(showList);
      return showList;
    },
  },
};
</script>
<style scoped>
.searchField {
  max-width: 300px;
}
.agentTime {
  max-width: 100px;
}
</style>
